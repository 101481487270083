// dropzone
ngx-dropzone {
  border: 2px dashed var(--theme-deafult) !important;
  height: auto !important;
  background: rgba(115, 102, 255, 0.1) !important;
  padding: 50px;
  i {
    font-size: 48px !important;
    color: var(--theme-default);
  }
}
.dropzone.dz-clickable * {
  background: transparent;
}
ngx-dropzone-label {
  z-index: 6 !important;
}
.dz-message {
  h6 {
    font-weight: 600;
    font-size: 16px;
    color: $dark;
  }
  span {
    color: $dark;
    font-size: 14px;
  }
}
.dz-message {
  h4 {
    font-weight: 200;
    margin-top: 20px;
  }
}

.node-content-wrapper.node-content-wrapper-active {
  &:hover {
    background-color: $primary-color;
    color: $white;
  }
}
.node-content-wrapper-active.node-content-wrapper-focused {
  background-color: $primary-color;
  color: $white;
}

.custom-datepicker {
  border: 1px solid $transparent-color !important;
  .form-select {
    color: #000;
  }
  .custom-day {
    font-size: 14px;
    text-align: center;
    padding: 0;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
  }
  ngb-datepicker,
  .dropdown-menu {
    border: none;
    border-radius: 8px;
    width: 100%;
  }
  .ngb-dp-header {
    padding: 20px 0 10px 0;
  }
  .ngb-dp-months {
    display: inherit;
    width: 100%;
    background-color: $white;
  }
  .ngb-dp-weekday {
    color: $theme-body-font-color;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11.2px;
  }
  .ngb-dp-weekdays {
    border-bottom: 1px solid $transparent-color;
  }
  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday,
  .ngb-dp-arrow {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-style: inherit;
    [ngbDatepickerDayView] {
      width: 100%;
      height: 30px;
      line-height: 30px;
    }
    .btn-light {
      background-color: $white !important;
      border-color: $white !important;
      font-size: 14px;
      color: $black !important;
      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: $primary-color !important;
        color: $white !important;
      }
    }
    .btn-link {
      color: $theme-body-font-color;
      .ngb-dp-navigation-chevron {
        width: 9px;
        height: 9px;
      }
    }
  }
  .ngb-dp-navigation-select {
    .form-select {
      width: 100px;
      &:first-child {
        margin-right: 15px;
      }
    }
  }
  .bg-light {
    background-color: $white !important;
  }
  .ngb-dp-month {
    &:first-child {
      .ngb-dp-week {
        padding-left: 15px;
      }
    }
    &:last-child {
      .ngb-dp-week {
        padding-right: 15px;
      }
    }
    .ngb-dp-week {
      &:last-child {
        padding-bottom: 15px;
      }
    }
  }
}

.swiper {
  .swiper-wrapper {
    height: 35px;
  }
}

.modal-dialog {
  .modal-content {
    .modal-body {
      .btn-close {
        position: absolute !important;
        right: 15px !important;
        top: 10px !important;
      }
    }
  }
}

.product-modal {
  .product-box {
    .product-details {
      a {
        :focus-visible {
          color: transparent;
        }
      }
      h6 {
        text-transform: capitalize;
      }

      .product-price {
        font-size: 22px;
        margin-bottom: 10px;
      }

      .product-view {
        padding: 20px 0;
        border-top: 1px dotted $semi-dark;
        border-bottom: 1px dotted $semi-dark;
      }

      .product-size {
        margin: 20px 0;

        ul {
          li {
            display: inline-block;
            + li {
              margin-left: 5px;
            }
          }
        }
      }

      .addcart-btn {
        margin-top: 30px;
      }

      .product-qnty {
        margin-bottom: 20px;

        .input-group {
          width: 35%;

          .btn {
            padding: 5px 12px;
          }

          .btn-primary {
            background-color: $semi-dark !important;
            border: 1px solid $semi-dark !important;
          }
        }
      }
    }
  }
}

.table-responsive {
  .form-select {
    color: $theme-body-font-color;
  }
}
.action {
  li {
    list-style: none;
  }
}

.accordion-button:not(.collapsed){
  color: $primary-color;
}

// sweetalert2 page css
.swal2-popup {
  .swal2-styled {
      font-size: 14px !important;
      /* background-color: var(--theme-deafult) !important; */
      padding: .625em 2em;
  }
  .swal2-title {
      font-size: 26px !important;
  }
  .swal2-actions {
      button {
          + button {
              margin-left: 5px;
          }
      }
  }
  &.swal2-sm {
      width: 24em;
      padding: 0 0 0.75em;
      .swal2-styled {
          padding: 0.425em 2em;
      }
      .swal2-icon {
          margin: 1em auto 0em;
          .swal2-icon-content {
              font-size: 2.15em;
          }
      }
      .swal2-title {
          max-width: 100%;
          margin: 0;
          padding: .4em 0.5em 0;
          font-size: 20px !important;
      }
      .swal2-html-container {
          margin: 0.5em 0.8em .15em;
          font-size: 1.085em;
      }
      .swal2-actions {
          margin: 0.65em auto 0;
      }
      .swal2-loader {
          width: 2em;
          height: 2em;
      }
  }
}
.swal2-close {
  &:focus {
      outline-color: $transparent-color;
  }
}
.swal2-content {
  #swal2-content {
      .btn {
          margin-bottom: 5px;
          &:last-child {
              margin-bottom: 0;
          }
      }
  }
}
.swal2-progress-steps {
  margin-bottom: 15px;
  .swal2-progress-step,
  .swal2-progress-step-line {
      background: $primary-color !important;
  }
  .swal2-progress-step {
      &.swal2-active-progress-step {
          background: $primary-color !important;
          ~ .swal2-progress-step,
          ~ .swal2-progress-step-line {
              background: rgba($primary-color, 0.2) !important;
          }
      }
  }
}
.swal2-container {
  z-index: 106000 !important;
}
.swal2-popup {
  font-size: 0.85rem !important;
}
.swal2-file, .swal2-input, .swal2-textarea {
  font-size: 0.83rem  !important;
}
.swal2-styled.swal2-confirm {
  background-color: $azul-color !important;
}
.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgb(0 113 188 / 50%) !important;
}